export function jmolCommand(command) {
  Jmol.script(jmolApplet0, command)
}

export function loadApplet(data, offset, coverImage, j2sPath, type) {
  // Prevent Jmol from submitting a record of each URL to chemapps.stolaf.edu
  delete Jmol._tracker

  /* initialisation information for the jmol instance */
  let Info = {
    width: "100%",
    height: "100%",
    use: "HTML5",
    debug: false,
    disableInitialConsole: true,
    j2sPath: j2sPath,
    coverImage: coverImage,
    deferUncover: true,
  }

  /* initialise Jmol and set the background to black */
  document.getElementById('jmol-applet').outerHTML = Jmol.getAppletHtml("jmolApplet0", Info)

  this.data = data
  this.offset = offset
  this.type = type
  this.refreshViewer()
}

export function resetViewer() {
  document.getElementById('jmol-actions').reset()
  this.refreshViewer()
}

export function refreshViewer() {
  const bondingVersion = getValue('jmol-bonding_version', '0')
  if (bondingVersion != null) {
    this.jmolCommand(`set bondingVersion ${bondingVersion}`)
  }
  const minBondDistance = getValue('jmol-min_bond_distance')
  if (minBondDistance != null) {
    this.jmolCommand(`set minBondDistance ${minBondDistance}`)
  }
  const bondTolerance = getValue('jmol-bond_tolerance')
  if (bondTolerance != null) {
    this.jmolCommand(`set bondTolerance ${bondTolerance}`)
  }
  this.jmolCommand('background black')
  const jmolLattice = this.getLattice()
  this.jmolCommand(`load ${this.data} ${jmolLattice}`)
  if (this.type == 'surface') {
    this.jmolCommand('color { occupancy >= 1.0 } TRANSLUCENT 0.75')
  }
  this.jmolCommand("rotate x 270")
  this.jmolCommand("rotate y 30")
  this.jmolCommand("rotate x 30")
  const style = getValue('jmol-style')
  if (style == 'wireframe') {
    this.jmolCommand('wireframe only')
  }
  else if (style == 'spacefill') {
    this.jmolCommand('spacefill only')
  }

  dispatchChange('jmol-background')
  dispatchChange('jmol-grid')
  dispatchChange('jmol-hydrogen')
  dispatchChange('jmol-labels')
  dispatchChange('jmol-spin')
}

export function onGridSelect(event) {
  const cube = event.target.value
  if (cube) {
    this.jmolCommand(`isosurface PLANE "xy" REVERSECOLOR MAP FILE "${cube}" OFFSET {0 0 ${this.offset}}`)
  }
  else {
    this.jmolCommand('isosurface DELETE')
  }
}

export function onCompareSelect(event) {
  this.refreshViewer()
  const comparison = getValue('jmol-compare')
  this.jmolCommand('zap 2.0')
  if (comparison) {
    this.jmolCommand('color OPAQUE')
    const jmolLattice = this.getLattice()
    this.jmolCommand(`load APPEND ${comparison} ${jmolLattice}`)
    this.jmolCommand('color (2.0) TRANSLUCENT 0.5')
    this.jmolCommand('model 0')
    const animate = getValue('jmol-animate')
    if (animate) {
      this.jmolCommand(`compare (1.0) (2.0) ${animate}`)
    }
  }
}

export function toggleAdvanced(event) {
  for ( const field of document.querySelectorAll('.input-container.advanced') ) {
    field.classList.toggle('hidden')
  }
}

export function getLattice() {
  let lattice = { x: 1, y: 1, z: 1 }
  for ( const direction in lattice ) {
    const repeat = getValue('jmol-repeat_' + direction)
    if (repeat) {
      lattice[direction] = repeat
    }
  }
  let jmol_lattice = `{ ${lattice.x} ${lattice.y} ${lattice.z} }`
  const packed = isChecked('jmol-packed', this.type =='bulk')
  if (packed) {
    jmol_lattice += " PACKED"
  }
  return jmol_lattice
}

function getValue(fieldName, fallback) {
  const field = document.getElementById(fieldName)
  if (field && field.value != null) {
    return field.value
  }
  return fallback
}

function isChecked(fieldName, fallback) {
  const field = document.getElementById(fieldName)
  if (field) {
    return field.checked
  }
  return fallback
}

function dispatchChange(fieldName) {
  const field = document.getElementById(fieldName)
  if (field) {
    field.dispatchEvent(new Event('change'))
  }
}
